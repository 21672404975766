import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { defaultLanguage } from "../../models/locale";
import { settings } from "../settings";
import { UserProfile } from "../../models/user";

export const MODE_GRID = "grid";
export const MODE_LARGE_PHOTO = "large_photo";
const keys = settings.keys;


interface AppState {
  credential?: UserProfile;
  language: string;
  postPhotoDisplayMode?: string;
  isRefresh?: string;
  isUpdata: boolean;
  allPostType: "US" | "CN",
  isRefreshAdminRecommendList: boolean
  showDetail: boolean;
  userLocalId: string;
  userLocalName: string;
  previousLocation:{
    lng: number;
    lat: number;
  } | null;
}

// load auth identity from local storage
export function _loadState(): AppState {
  const appRaw =
    typeof window !== "undefined" ? localStorage.getItem(keys.app) : null;
  if (appRaw !== null) {
    const appParse = JSON.parse(appRaw) as AppState;
    appParse.isUpdata = false;
    appParse.showDetail = false;
    return appParse;
  }
  return {
    userLocalId: "",
    language: defaultLanguage,
    postPhotoDisplayMode: MODE_LARGE_PHOTO,
    isUpdata: false,
    allPostType: "US",
    isRefreshAdminRecommendList: false,
    showDetail: false,
    userLocalName: "",
    previousLocation: null,
  };
}

const appSlice = createSlice({
  name: keys.app,
  initialState: _loadState(),
  reducers: {
    setIdentity(state, action: PayloadAction<UserProfile | undefined>) {
      state.credential = action.payload;
      localStorage.setItem(keys.app, JSON.stringify(state));
    },
    setLanguage(state, action: PayloadAction<string>) {
      state.language = action.payload;
      localStorage.setItem(keys.app, JSON.stringify(state));
    },
    setUserLocalId(state, action: PayloadAction<string>) {
      state.userLocalId = action.payload;
      localStorage.setItem(keys.app, JSON.stringify(state));
    },
    setUserLocalName(state, action: PayloadAction<string>) {
      state.userLocalName = action.payload;
      localStorage.setItem(keys.app, JSON.stringify(state));
    },
    setPostPhotoDisplayMode(state, action: PayloadAction<string>) {
      state.postPhotoDisplayMode = action.payload;
      localStorage.setItem(keys.app, JSON.stringify(state));
    },
    setIsUpdata(state, action: PayloadAction<boolean>) {
      state.isUpdata = action.payload;
      // localStorage.setItem(keys.app, JSON.stringify(state));
    },
    setAllPostType(state, action: PayloadAction<"US" | "CN">) {
      state.allPostType = action.payload;
      localStorage.setItem(keys.app, JSON.stringify(state));
    },
    setIsRefreshAdminRecommendList(state, action: PayloadAction<boolean>) {
      state.isRefreshAdminRecommendList = action.payload;
      localStorage.setItem(keys.app, JSON.stringify(state));
    },
    setShowDetail(state, action: PayloadAction<boolean>) {
      state.showDetail = action.payload;
      // showDetail.setItem(keys.app, JSON.stringify(state));
    },
    setPreviousLocation(state, action: PayloadAction<{
      lng: number;
      lat: number;
    } | null>) {
      state.previousLocation = action.payload;
    },
    clearIdentity(state) {
      state = {
        language: defaultLanguage,
        userLocalId: "",
        isUpdata: false,
        allPostType: "US",
        isRefreshAdminRecommendList: false,
        showDetail: state.showDetail,
        userLocalName:"",
        previousLocation: null,
      };
      localStorage.removeItem(keys.app);
    },
    setIsRefresh(state, action: PayloadAction<string>) {
      state.isRefresh = action.payload;
      localStorage.setItem(keys.app, JSON.stringify(state));
    },
  },
});

export const {
  setIdentity,
  setLanguage,
  setUserLocalId,
  setUserLocalName,
  setPostPhotoDisplayMode,
  clearIdentity,
  setIsRefresh,
  setIsUpdata,
  setAllPostType,
  setIsRefreshAdminRecommendList,
  setShowDetail,
  setPreviousLocation,
} = appSlice.actions;
export default appSlice.reducer;
