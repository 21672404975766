import { createApi } from "@reduxjs/toolkit/query/react";
import { Response, Result, Value } from "../../models/common";
import { buildQueryWithReAuth } from "../../tools/toolkit/helper";
import {
  GetFeedbackListProps,
  updateFeedbackProps,
  adminGetFeedbackListResponse,
} from "src/models/feedback";

export const adminFeedbackApi = createApi({
  reducerPath: "adminFeedback",
  baseQuery: buildQueryWithReAuth(process.env.REACT_APP_ADMIN_API_URL),
  endpoints(builder) {
    return {
      // 获取全部反馈列表
      getFeedbackList: builder.mutation<
        Result<adminGetFeedbackListResponse>,
        GetFeedbackListProps
      >({
        query: (GetFeedbackListProps) => ({
          url: `adminmanamgement`,
          method: "POST",
          body: GetFeedbackListProps,
        }),
        transformResponse: (res: Response<adminGetFeedbackListResponse>) =>
          new Value(res),
      }),
      // 回复
      replyFeedback: builder.mutation<Result<undefined>, updateFeedbackProps>({
        query: (updateFeedbackProps) => ({
          url: `adminmanamgement`,
          method: "POST",
          body: updateFeedbackProps,
        }),
        transformResponse: (res: Response<undefined>) => new Value(res),
      }),
    };
  },
});

export const { useGetFeedbackListMutation, useReplyFeedbackMutation } =
  adminFeedbackApi;
