import { Box, Avatar } from '@mui/material';
import { ChatMessage } from 'src/models/chat';
import { useTranslation } from 'react-i18next';
import icoPlay from "src/images/chatDialog/ico-play.svg";
import { appConfig } from "src/app-config";
import { PostEntity, PostMediaType, getCoverImage, getPostMediaType } from 'src/models/post';
import { getPostLabel, getSharePostName } from 'src/tools/utils/chatUtil';
import IcoLocation from "src/images/post/ico-location-small-blue.svg";
import { CommonUser } from 'src/models/user';
import DefaultAvatar from "src/images/defaultIcons/default-avatar.png";
import { getShortDateTimeInWordDescription2 } from "src/tools/utils/timeUtil";

interface Props {
  row: ChatMessage,
  isOwn: boolean,
}

const SharePostMessage = ({ row, isOwn }: Props) => {
  const { t, i18n } = useTranslation();
  const post = JSON.parse(row.content) as PostEntity;

  return (
    <Box style={{ display: "flex", alignItems: "flex-start", cursor: "pointer", flexDirection: "column", width: "236px" }}
      onClick={() => window.open(`${window.location.protocol}//${window.location.host}${appConfig.paths.webPostDetail}?id=${post.messageid}`, "_blank")}>
      <Box sx={{
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "16px",
        marginBottom: "6px",
        lineClamp: 2,
        WebkitLineClamp: 2,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        textAlign:"left"
      }}>{getPostLabel(row, t)}</Box>
      <Box sx={{
        width: "100%",
        position: "relative"
      }}>
        <img alt="post_image" style={{ width: "100%", maxHeight: "134px", objectFit: "cover" }} src={getCoverImage(post)} />
        {getPostMediaType(post) === PostMediaType.Video && <Box
          component="img"
          src={icoPlay}
          sx={{
            position: "absolute",
            left: "42%",
            top: "42%"
          }}
        />}
        <Box sx={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          padding: '7px',
          background: "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))",
        }}>
          <Avatar alt="user_image" style={{ width: "18px", height: "18px", objectFit: "cover", marginRight: "5px" }} src={post.user_avatar || DefaultAvatar} />
          <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "12px", fontWeight: 400, color: 'white' }}>{getSharePostName(row)}</Box>
        </Box>
      </Box>
      <Box sx={{ textAlign: "left" }}>
        <Box sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
          <img alt="ico-location" src={IcoLocation} style={{ width: "12px", height: "14px", marginRight: "5px" }} />
          <Box sx={{ maxWidth: "222px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontSize: "12px" }}>{post?.loc_name}</Box>
        </Box>
        <Box sx={{ marginTop: '6px', fontSize: '12px', fontWeight: 400, color: "rgba(85, 85, 85, 1)" }}>
          {getShortDateTimeInWordDescription2(post.timestamp * 1000)}
        </Box>
      </Box>
    </Box>
  )
}


export default SharePostMessage;