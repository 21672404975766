import { Box, Dialog, IconButton } from "@mui/material";
import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";

interface Props {
  url: string;
  open: boolean;
  handleClose: () => void;
}

const PreviewPicture: FC<Props> = ({ url, open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <Box
        component="img"
        src={url}
        sx={{
          maxWidth: "100%",
          maxHeight: "100vh",
          height: "auto",
          width: "auto",
        }}
      />
      {open && (
        <IconButton
          onClick={handleClose}
          style={{
            position: "fixed",
            top: 8,
            left: 8,
            color: "#fff",
            zIndex: 1301,
            background: "#333333",
            borderRadius: "50%",
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Dialog>
  );
};

export default PreviewPicture;
